.drawer-comp {
    height: 100vh;
    width: 100vw;
    margin-top: -2rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-items: center;
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);

    transform: translateZ(0);
    transition: transform 2s;
}

.menu {
    position: absolute;
    right: 0;

    height: 100vh;
    width: 50vw;
    background-color: white;

    transform: translateX(-100%);
    transition: 1s;
}

.slide {
    transform: translateX(0);
    transition: 1s;
}

.title-container {
    display: flex;
    justify-content: space-around;
    align-items: center;

    margin-bottom: 1rem;
}

.drawer-comp__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 4rem;
}

.drawer-comp__content li {
    list-style: none;
}

.drawer-comp__content i {
    font-size: 1rem;
    padding-left: 0.5rem;
}

/* ----------- Nav Links ----------- */
.drawer-comp__nav-links {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    margin-left: 0.6rem;
}

.drawer-comp__nav-links button {
    padding: 0.2rem 0.5rem;
    width: 90%;

    font-family: 'Work Sans';
    font-weight: 600;
    border-radius: 5px;

    background-color: white;
}