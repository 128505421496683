nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    margin: 2rem auto;

    position: fixed;
    z-index: 1;

    transform: translateY(0);
    animation: nav-clip 1s 3s ease-out both;
}

/* ---------- Nav Container ---------- */
.nav-container {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: transparent;
    border-radius: 5px;
    padding: 1rem;
}

.nav-container i {
    font-size: 2rem;
    padding: 0 1.75rem;
    transition: 300ms;
}

/* ---------- Nav Links Container ---------- */
.nav-links {
    list-style: none;
    text-align: center;

    display: flex;
    column-gap: 1rem;
    align-items: center;
}

.nav-links li {
    font-weight: 600;
    transition: 300ms;
}

.nav-links li:hover {
    color: #DD4A48;
    transform: scale(110%);
    transition: 300ms;
}

.nav-links button {
    padding: 0.2rem 0.5rem;

    border-radius: 5px;
}

.nav-links button {
    padding: 0.2rem 0.5rem;

    font-family: 'Work Sans';
    font-weight: 600;
    border-radius: 5px;

    background-color: #F5EEDC;
}

.nav-links button:hover {
    cursor: pointer;
}
.nav-links i {
    padding: 0;
    font-size: 1rem;
}

/* ---------- Drawer Component ---------- */
.div-drawercomp {
    transform: translateZ(-100%);
    transition: transform 2s;
}

.visible {
    transform: translateY(0);
    transition: 1s;
}

.invisible {
    transform: translateY(-200%);
    transition: 1s;
}

@media (min-width: 720px) {
    nav {
        display: inline-block;
        margin: 0;
    }

    .nav-container {
        width: 100%;
        margin: 0;
        border-radius: 0;

        display: flex;
        justify-content: space-between;
        align-items: center;

        background-color: #97BFB4;
    }

    nav i:hover {
        color: #DD4A48;
        transform: scale(110%);
        transition: 300ms;
    }
}

@media (min-width: 1400px) {
    nav i {
        font-size: 2.5rem;
    }

    .nav-links li {
        font-size: 1.1rem;
    }
}

/* Hamburger menu */
.nav-container .mobile-navigation {
    display: none;
}

@media (max-width: 720px) {
    .nav-container .navigation {
        display: none;
    }

    .nav-container .mobile-navigation {
        display: block;
    }

    .nav-container .mobile-navigation:hover {
        cursor: pointer;
        transform: scale(110%);
    }
}