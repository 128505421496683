.main-carousel-container {
    position: relative;
    display: flex;
    align-items: center;
}

.carousel-icon {
    color: #4F091D;
    position: absolute;
    transition: 300ms;
}

.right {
    right: -1rem;
}

.left {
    left: -1rem;
}

.carousel {
    width: 100%;
    height: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
}

.carousel::-webkit-scrollbar {
    display: none;
}

.carousel-item {
    display: inline-block;
    margin: 0 1rem 1rem 1rem;
    scroll-snap-align: center;
    justify-self: center;
}

@media (min-width: 720px) {
    .carousel-icon {
        display: none;
    }

    .carousel {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
    }
}

@media (min-width: 1200px) {
    .carousel {
        column-gap: 2rem;
    }
}