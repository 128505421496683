/* Front */
.front {
    background-color: #97BFB4;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.front-container {
    width: 85%;
    max-width: 340px;
    margin: 0 auto;

    text-align: center;
}

.front h1 {
    font-size: 3.2rem;
    color: #F5EEDC;
    animation: inner-left 1s 1s ease both;
}

.front p {
    font-size: 1.5rem;
    animation: text-clip 1s 2s cubic-bezier(0.5, 0, 0.1, 1) both;
}

@keyframes text-clip {
    from {
      clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    }
    to {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }

@keyframes inner-left {
    from {
        transform: translateX(-300%);
      }
      to {
        transform: none;
      }
}

@media (min-width:1400px) {
    .front-container {
        max-width: none;
    }

    .front h1 {
        font-size: 4.75rem;
    }

    .front p {
        font-size: 1.7rem;
    }
}

/* About me */

.about-me {
    background-color: #F5EEDC;
    height: 100%;
    min-height: 100vh;
    padding-top: 3rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-me-content {
    background-color: #97BFB4;
    padding: 1.5rem;
    border-radius: 5px;
    max-width: 340px;
    text-align: justify;
    margin-bottom: 4rem;
}

@media (min-width: 720px) {
    .about-me {
        min-height: 65vh;
    }

    .about-me-content {
        width: 75%;
        max-width: 1200px;
    }
}

/* Techs */

.techs {
    background-color: #97BFB4;
    height: 100%;
    min-height: 35vh;
    text-align: center;
}

.techs-content {
    padding: 3rem 0;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.techs ul {
    list-style: none;
    margin-top: 0.75rem;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
}

.techs li {
    margin-bottom: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.techs i {
    font-size: 2rem;
    margin-bottom: 0.3rem;
}

@media (min-width:720px) {
    .techs ul {
        grid-template-columns: repeat(5, 1fr);
        row-gap: 1.5rem;
        margin: 3rem auto 1rem auto;
    }

    .techs i {
        font-size: 300%;
    }

    .techs i:hover {
        color: #DD4A48;
    }
}

@media (min-width:1400px) {
    .techs i {
        font-size: 375%;
    }
}

/* Proyects */

.proyects {
    background-color: #F5EEDC;
    text-align: center;
    height: 100%;
    min-height: 100vh;
    padding-top: 3rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (min-width:720px) {
    .proyects {
        height: 100%;
        min-height: 75vh;
    }

    .proyects h2 {
        margin-top: 3rem;
    }
}

/* Contact */

.contact {
    background-color: #4F091D;
    text-align: center;
    height: 100%;
    min-height: 50vh;
    padding: 3rem 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact h2 {
    color: #F5EEDC;
}

.contact-container {
    background-color: #F5EEDC;
    border-radius: 5px;
    padding: 1rem;

    width: 85%;
    max-width: 340px;
}

.contact-container p {
    width: 85%;
    margin: 1rem auto;
}

.contact-container span {
    color: black;
    font-weight: 600;
}

.contact-container p span:hover {
    cursor: pointer;
    color: #DD4A48;
    transition: 300ms;
}

.contact .social {
    display: grid;
    grid-template-columns: 1fr 1fr;

    width: 40%;
    margin: 1.5rem auto 0 auto;
}

.contact a {
    text-decoration: none;
}

.social i {
    font-size: 2.5rem;
    color: #4F091D;
}

@media (min-width:720px) {
    .contact {
        height: 35vh;
    }

    .contact-container {
        width: 50%;
        max-width: 700px;
    }

    .contact-container span {
        transition: 300ms;
    }

    .social i:hover {
        color: #DD4A48;
        transform: scale(110%);
        transition: 300ms;
    }
}